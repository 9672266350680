import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Navbar } from "../layout/navbar";
import './../style/cart.css'
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark, faPen, faArrowRight, faShoppingCart } from '@fortawesome/free-solid-svg-icons'; // Import the cancel icon


export const Cart = () => {
  const [product, setProduct] = useState(null);
    const { id } = useParams();

    const navigate = useNavigate();

    const [cart, setCart] = useState(() => {
      const savedCart = localStorage.getItem('cart');
      return savedCart ? JSON.parse(savedCart) : [];
    });
  
    const goTocheckout = () => {
     // console.log(savedCart);
     if(cart.length){
     navigate(`/checkout`);
     }else{
      
     }
    };
    // Fetch product details based on the ID
  useEffect(() => {
    // Simulate an API call (replace with real API call)
    const fetchProductDetails = async () => {
      const productData = {
        1: { name: 'Product 1', price: '20.00', description: 'This is product 1',image_url:'product-1.png' },
        2: { name: 'Product 2', price: '30.00', description: 'This is product 2',image_url:'product-2.png' },
        // Add more products here as needed
      };

      const productDetails = productData[id]; // Fetch product based on ID
      setProduct(productDetails);
    };

    fetchProductDetails();
  }, [id]);

  useEffect(() => {
    localStorage.setItem('cart', JSON.stringify(cart));
  }, [cart]);

  // Function to remove an item from the cart
  const removeItemFromCart = (removitem) => {
    const updatedCart = cart.filter(
      (item) => !(item.id === removitem.id && item.size === removitem.size && item.color === removitem.color)
    );
    setCart(updatedCart);
  };

  const editItemFromCart = (id,index) => {
    navigate(`/editproduct/${id}/${index}`); 
  };

  const goToshop=()=>{
    navigate(`/GearUp`);
  }

  const calculateTotal = () => {
    return cart.reduce((total, item) => {
      return total + item.price * item.qty; // Multiply price by quantity for each item
    }, 0);
  };
  
  const totalWithFee = parseFloat((calculateTotal() + 6.99).toFixed(2));


  return (
    <div>
     <Navbar />

                <div className='checkmain'>

                         <div className='checkoutstripcart'>Your Cart</div> 
                         <hr /> 
                         <div className='checkoutdetailcart'>
                                                        <div className='checkitem'  style={{ color: "#ffffff"}}>Image</div>

                                                        <div className='checkitem'  style={{ color: "#ffffff"}}>Name</div>
                                                        <div className='checkitem'  style={{ color: "#ffffff"}}> Qty</div>
                                                        <div className='checkitem'  style={{ color: "#ffffff"}}> Price</div>
                                                        <div className='checkitem'  style={{ color: "#ffffff"}}> Size</div>
                                                        <div className='checkitem'  style={{ color: "#ffffff"}}> Color</div>
                                                        <div className='checkitem'  style={{ color: "#ffffff"}}> Subtotal</div>
                          
                          
                          </div> 
                          <hr />

                          {cart.map((item, index) => {
                const productImages = item.image_url ? item.image_url.split(",") : ['default.jpg'];

                // Explicitly return the JSX
                return (
                    <div key={index}>
                        <div className='checkoutdetailcart' style={{opacity:"0.8s", color: "#ffffff"}}>
                            <div className='checkitem2'>
                                <img
                                    src={item.image_url
                                        ? `https://727instrumental.org/backend/public/products/${item.cartimg}`
                                        : 'https://727instrumental.org/backend/public/products/default.jpg'}
                                    alt={item.name || "Default Product"}
                                    className="itemImage2"
                                />
                            </div>
                            <div className='checkitem2' style={{ fontSize:'18px'}}>{item.name}</div>
                            <div className='checkitem2'  style={{ fontSize:'18px'}}>{item.qty}</div>
                            <div className='checkitem2'  style={{ fontSize:'18px'}}>{item.price}</div>
                            <div className='checkitem2'  style={{ fontSize:'18px'}}>{item.size}</div>
                            <div className='checkitem2'  style={{ fontSize:'18px'}}>{item.color}</div>
                            <div className='checkitem2'  style={{ fontSize:'18px'}}>{item.subtotal}</div>
                        </div>
                        <div className='actionlinks'>
                                <button className='cancle' onClick={() => removeItemFromCart(item)} >
                                    <FontAwesomeIcon icon={faXmark} /> REMOVE
                                </button>
                                <button className='edit' onClick={() => editItemFromCart(item.id,index)}>
                                    <FontAwesomeIcon icon={faPen} /> EDIT
                                </button>
                        </div>
                        <hr />

                        
                    </div>
                    
                );
            })}
                     <div className='shipMain'>

                    <div style={{width:'100%',display: 'inline-block',marginTop: '40px'}}> 
                         <div style={{ width:'40%', textAlign:'left', float:'right',display:'block'}}>Cart totals</div>
                    </div>

                    <div className='shiptotal'> 
                       <div style={{width:'100%',borderBottom: '2px solid white',display: 'block ruby',lineHeight: '50px' }}>
                                <div style={{ width:'40%', textAlign:'left', float:'left',display:'block'}}>Subtotal</div>
                                <div style={{ width:'60%', textAlign:'left', float:'left',display:'block'}}>${calculateTotal().toFixed(2)}</div>
                         </div>

                         <div style={{width:'100%',borderBottom: '2px solid white',display: 'flex' , marginTop:'10px', marginBottom:'10px',height: '70px'}}>
                                <div style={{ width:'40%', textAlign:'left', float:'left',display:'block'}}>Shipping</div>
                                <div style={{ width:'60%', textAlign:'left', float:'left',display:'block'}}>Flat rate:$6.99 <br/>for domestic shipping</div>
                         </div>
                         <div style={{width:'100%',borderBottom: '2px solid white',display: 'block ruby',lineHeight: '50px' }}>
                                <div style={{ width:'40%', textAlign:'left', float:'left',display:'block'}}>Tax</div>
                                <div style={{ width:'60%', textAlign:'left', float:'left',display:'block'}}>$0.00</div>
                         </div>

                         <div style={{width:'100%',lineHeight: '50px' }}>
                                <div style={{ width:'40%', textAlign:'left', float:'left',display:'block'}}>Total</div>
                                <div style={{ width:'60%', textAlign:'left', float:'left',display:'block'}}>${totalWithFee}</div>
                         </div>

                         <div style={{width:'100%' }}>
                                <div style={{ width:'50%', textAlign:'left', float:'left',display:'block'}}><button style={{ color: "#fff", float: "left", height: "40px", background: "none", marginTop: "20px", borderRadius: "38px", fontSize: "18px", width: '200px', background: "#ED592B"}} className='checkout' type='submit' value="CheckOut"  onClick={() => goTocheckout()} >Proceed To Checkout</button></div>
                                <div style={{ width:'50%', textAlign:'left', float:'left',display:'block'}}><span style={{color: "#fff", height: "50px", background: "none", marginLeft: "30px", float:"right", marginTop:'30px'}} onClick={() => goToshop()}>Continue Shopping <FontAwesomeIcon icon={faArrowRight} /> </span></div>
                         </div>
                    </div>

                    
            </div>                         
                  </div>

            
    </div>
  )
}