import './App.css';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Homepage } from "./components/homepage";
import { Navbar } from './layout/navbar'; // Adjust the path as necessary
import { Donate } from './components/donate';
import { ContactUs } from "./components/contactUs";
import { AboutUs } from "./components/aboutUs";
import { GearUp } from './components/gearUp';
import { Events } from './components/events';
import {ProductDetails } from './components/productDetails';
import {Buytickets } from './components/buy-tickets';

import {EditProductDetails } from './components/editproductDetails';
import {Checkout } from './components/checkout';
import {Ticketcheckout } from './components/ticketcheckout';
import {Cart } from './components/cart';
import {Success } from './components/success';

import {Messagesend } from './components/messagesend';

import { Fundraiser } from "./components/fundraiser";

import { Fundraisercheckout } from "./components/fundraisercheckout";







function App() {
  return (<>
    <Router>
      {/* This should render the navigation links */}
      <div className="App"> {/* App-specific styles */}
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="/donate" element={<Donate />} />
          <Route path="/aboutUs" element={<AboutUs />} />
          <Route path="/contactUs" element={<ContactUs />} />
          <Route path="/GearUp" element={<GearUp />} />
          <Route path="/events" element={<Events />} />
          <Route path="/product/:id" element={<ProductDetails />} />
          <Route path="/checkout" element={<Checkout />} />
          <Route path="/cart" element={<Cart />} />
          <Route path="/payment-success" element={<Success />} />
          <Route path="/editproduct/:id/:indexupdate" element={<EditProductDetails />} />
          <Route path="/buy-tickets/:id" element={<Buytickets/>} />

          <Route path="/ticket-checkout" element={<Ticketcheckout />} />

          <Route path="/messagesend" element={<Messagesend />} />

          <Route path="/fundraiser" element={<Fundraiser />} />
a
          <Route path="/fundraisercheckout/:id" element={<Fundraisercheckout />} />
        </Routes>
      </div>
    </Router>
    </>
  );
}

export default App;
