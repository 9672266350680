import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { Navbar } from "../layout/navbar";
import './../style/checkout.css'
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark, faPen, faArrowRight, faShoppingCart } from '@fortawesome/free-solid-svg-icons'; // Import the cancel icon

let debounceTimer;
const debounce = (callback, delay) => {
  clearTimeout(debounceTimer);
  debounceTimer = setTimeout(callback, delay);
};

export const Fundraisercheckout = () => {
  const [product, setProduct] = useState(null);
  const [termsAccepted, setTermsAccepted] = useState(false);
 
  const [isPayPalButtonDisabled, setIsPayPalButtonDisabled] = useState(true);
  const [fname,setfname]= useState('');
  const [lname,setlname]= useState('');

 

  const [YourFullName,setYourFullName]= useState('');
  const [useremail, setUseremail] = useState("");
  const [Address,setAddress]= useState('');
  const [City,setCity]= useState('');
  const [State,setState]= useState('');
  const [Zip,setZip]= useState('');

  const [ShipFullName,setShipFullName]= useState('');
  const [ShipAddress,setShipAddress]= useState('');
  const [ShipCity,setShipCity]= useState('');
  const [ShipState,setShipState]= useState('');
  const [ShipZip,setShipZip]= useState('');
  const [shippingandaddres,setshippingandaddres]= useState(false);

 

  const [isTyping, setIsTyping] = useState(false);

  const YourFullNameRef = useRef(YourFullName);
  const useremailRef = useRef(useremail);

  const AddressRef = useRef(Address);
  const CityRef = useRef(City);
  const StateRef = useRef(State);
  const ZipRef = useRef(Zip);

  const ShipFullNameRef = useRef(ShipFullName);
  const ShipAddressRef = useRef(ShipAddress);
  const ShipCityRef = useRef(ShipCity);
  const ShipStateRef = useRef(ShipState);
  const ShipZipRef = useRef(ShipZip);

  useEffect(() => {

    
    YourFullNameRef.current = YourFullName;
    useremailRef.current = useremail; // Update ref whenever state changes
    AddressRef.current = Address; // Update ref whenever state changes
    CityRef.current = City; // Update ref whenever state changes
    StateRef.current = State; // Update ref whenever state changes
    ZipRef.current = Zip; // Update ref whenever state changes


    
    
  }, [useremail, YourFullName,Address,City,State,Zip]);


  const validateFields = () => {
    // Check if all required fields are filled
    return (
      
      YourFullName.trim() !== "" &&
      useremail.trim() !== "" &&
      Address.trim() !== "" &&
      City.trim() !== "" &&
      State.trim() !== "" &&
      Zip.trim() !== "" 
    );
  };

  useEffect(() => {
    // Update PayPal button state based on field validation
    setIsPayPalButtonDisabled(!validateFields());
  }, [useremail, YourFullName,Address,City,State,Zip]);


  const handleInputChange = (setter) => (e) => setter(e.target.value);

  

  
  

  
  
  

    const { id } = useParams();

    const navigate = useNavigate();

    const [ticketcart, setTicketcart] = useState(() => {
      const savedCart = localStorage.getItem('ticketcart');
      return savedCart ? JSON.parse(savedCart) : [];
    });
  
    const addToCart = (item) => {
      const updatedCart = [...ticketcart, item];
      setTicketcart(updatedCart);
      localStorage.setItem('ticketcart', JSON.stringify(updatedCart));
      const savedCart = localStorage.getItem('ticketcart');

     // console.log(savedCart);
     navigate(`/checkout`);
    };
    // Fetch product details based on the ID
  useEffect(() => {
    // Simulate an API call (replace with real API call)
    const fetchProductDetails = async () => {
      try {
            const response = await fetch('https://727instrumental.org/backend/public/api/fundraiserdetails', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({ fundraiserid:id }), // Sending productid in the body
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

           const productData = await response.json(); 
           console.log("dhirendra");

           console.log(productData.fundraiser);
        
           setProduct(productData.fundraiser);

          } catch (error) {
            // setError(error.message);
            // setLoading(false);
         }
    };

    fetchProductDetails();
  }, [id]);


  const handleCreateOrder = async (data, actions) => {
    // Validate the cart before proceeding

    const currentYourFullName = YourFullNameRef.current;
    const currentEmail = useremailRef.current;
    const currentAddress =  AddressRef.current;
    const currentCity = CityRef.current;
    const currentState = StateRef.current;
    const currentZip = ZipRef.current;

   
   

    if (product.length === 0) {
      alert("Sorry, your cart is empty.");
     
      throw new Error("Ticket Cart is empty"); // Stops the PayPal flow
    }

  // console.log(useremail);
   //alert(useremail+'dhirendra');
  
    try {
      // Send the create order request to the backend
      const response = await fetch("https://727instrumental.org/backend/public/api/paypal/create-order-fundraiser", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          useremail:currentEmail,
          name:currentYourFullName,
          fundraiseid:id,
          address:currentAddress,
          city:currentCity,
          state:currentState,
          zip:currentZip,
          totalprice:product[0].amount,
        }),
      });
  
      if (!response.ok) {
        throw new Error("Failed to create order");
      }
  
      const orderData = await response.json();
      return orderData.id; // Return the PayPal order ID
    } catch (error) {
      console.error("Error creating order:", error);
      alert("Unable to create order. Please try again later.");
      throw error; // Stops the PayPal flow
    }
  };


  const handleOnApprove = async (data, actions) => {
    try {
      // Capture the payment on the backend
      const response = await fetch("https://727instrumental.org/backend/public/api/paypal/capture-order-fundraiser", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ orderID: data.orderID }) // Ensure orderID is being sent correctly
      });
  
      // Check if response is successful
      if (!response.ok) {
        throw new Error("Failed to capture the order.");
      }
  
      const captureData = await response.json();
      console.log("Capture Data:", captureData);
  
      if (captureData.success) {
        setTicketcart([]); // Empty the cart in state
         localStorage.removeItem('ticketcart'); // 
        window.location.href = "/payment-success";  // Redirect to your success page, or use window.close() if in pop-up mode
      } else {
        alert("Payment verification failed.");
      }
  
      // Redirect or close the window (depending on how the PayPal window was opened)
      
    } catch (error) {
      console.error("Error in payment approval:", error);
      alert("Something went wrong. Please try again.");
    }
  };


 

  
  

  const calculateTotal = () => {
    return ticketcart.reduce((total, item) => {
      return total + item.price * item.qty; // Multiply price by quantity for each item
    }, 0);
  };

  


  const totalWithFee = parseFloat((calculateTotal() + 6.99).toFixed(2)) ;



  const handleOnClick = (data, actions) => {

    const currentYourFullName = YourFullNameRef.current;
    const currentEmail = useremailRef.current;
    const currentAddress =  AddressRef.current;
    const currentCity = CityRef.current;
    const currentState = StateRef.current;
    const currentZip = ZipRef.current;

   

     if (currentYourFullName.trim() === "") {
                        alert("Please provide your Name");
                        return actions.reject(); // Prevent PayPal popup
    }else if(!currentEmail || currentEmail.trim() === ""){
                    alert("Please provide a valid email address.");
                    return actions.reject(); // Prevent PayPal popup

    }else if(currentAddress.trim() === ""){

      alert("Please provide a valid   Address.");
      return actions.reject(); // Prevent PayPal popup

    }else if(currentCity.trim() === ""){
                  alert("Please provide a valid City.");
                  return actions.reject(); // Prevent PayPal popup
    }else if(currentState.trim() === ""){
      alert("Please provide a valid State.");
      return actions.reject(); // Prevent PayPal popup
    }else if(currentZip.trim() === ""){
      alert("Please provide a valid Zip");
      return actions.reject(); // Prevent PayPal popup
    }
    
   
    //console.log("User email:", currentEmail);

    

    
   
    return actions.resolve();
   
  };





  

 

  
  
   

 
  
  return (
    <div>
     <Navbar />

                <div className='checkmain' >

                         <div className='checkoutstrip'> FUNDRAISER CHECKOUT</div> 

                   <div className='checkoutinner'>      


                         <div className='checkoutleft'>

                           <div className='shippingdetails'> YOUR  DETAILS</div>


                           <div className='fandlname'> 
                           <input type='text'   name='fname' className='inputbox' value={YourFullName}  onChange={handleInputChange(setYourFullName)} placeholder="Enter your Full Name " autoComplete="off"  />
                             </div>
                           <div className='fandlname'> <input type='text' className='inputbox' name='email' value={useremail}  onChange={handleInputChange(setUseremail)} placeholder="Enter your email" autoComplete="off"  />   </div>
                            
                           <div className='fandlname'> <input type='text' className='inputbox' name='address' value={Address}  onChange={handleInputChange(setAddress)}    placeholder="Enter your Address" autoComplete="off" />  </div>

                           <div className='fandlname'> <input type='text' className='inputbox' name='city' value={City}  onChange={handleInputChange(setCity)}  placeholder="Enter your City" autoComplete="off"  />  </div>

                           <div className='fandlname'> <input type='text' className='inputbox' name='state' value={State}  onChange={handleInputChange(setState)} placeholder="Enter your State" autoComplete="off"  />  </div>
                         
                           <div className='fandlname'> <input type='text' className='inputbox' name='zip' value={Zip}  onChange={handleInputChange(setZip)}  placeholder="Enter your  Postal / Zip Code" autoComplete="off"  />  </div>

                          
                           <div className='fandlname'> 
                             </div>
                            
                           
                           

                          
                         </div>

                         

                         

                         <div className='checkoutright'>

                                              <div className='checkoutdetail'>
                                                                              

                                                                              <div className='checkoutitem' style={{width:'50%'}}>Name</div>
                                                                             
                                                                              <div className='checkoutitem' style={{width:'50%'}} >Price</div>
                                                                              
                                                                              
                                                
                                                </div> 


                                                {product?.length > 0 ? (

<div >
<div className='checkoutdetailcart' style={{opacity:"0.8s", color: "#ffffff"}}>
    
    <div className='checkitem3' style={{ fontSize:'16px',width:'50%'}}>{product[0].name}</div>
    
    <div className='checkitem2'  style={{ fontSize:'16px',width:'50%'}}>{product[0].amount}</div>
   
</div>
<div className='actions'>
       
</div>
<hr />


</div>
  ): null}

                                          


<div className='shipMain'>



<div className='shipmain2'> 
   

    
     

     <div style={{width:'100%',lineHeight: '50px' }}>
            <div style={{ width:'40%', textAlign:'left', float:'left',display:'block'}}>Total</div>
           
           
            {product?.length > 0 ? (
            <div style={{ width:'60%', textAlign:'left', float:'left',display:'block'}}>${product[0].amount}</div>
          ): null}
     </div>

    
</div>


</div>


                                        <div className='checkoutdetail'>

                                          

                                        <PayPalScriptProvider options={{ "client-id": "AVNx-Z-S7SxRFQebH6kIVPMRO_itIRNgI-gDoeBKr6lumzCfAPTmUnGLOHoiv63IO6bXf6iLrk9UHBYq", currency: "USD" }}>
                                              <div style={{margin: "0 auto"}}>

                                              {product?.length > 0 ? (
  <PayPalButtons
  createOrder={handleCreateOrder}
  onApprove={handleOnApprove}
  onError={(err) => console.error("PayPal error:", err)}
 
  onClick={handleOnClick}
  disabled={isPayPalButtonDisabled}
  style={{
    label: "pay", // Changes the button label
  }}
 
/>
) : (
  <p>Loading product details...</p>
)}
                                                
                                                
                                              </div>
                                            </PayPalScriptProvider>


                                           

                                        
                                                                              
                                                
                                                </div> 



                         </div>

                         

                </div>    


                                              
                  </div>
    </div>
  )
}