import React , { useEffect, useState } from 'react';
import { Outlet, Link } from "react-router-dom";
import '../style/home.css';
import './../App.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark, faPen, faArrowRight, faShoppingCart } from '@fortawesome/free-solid-svg-icons';
export const Navbar = () => {
  const [navHeight, setNavHeight] = useState("0%");

   const [cart, setCart] = useState(() => {
        const savedCart = localStorage.getItem('cart');
        return savedCart ? JSON.parse(savedCart) : [];
      });

      useEffect(() => {
          localStorage.setItem('cart', JSON.stringify(cart));
        }, [cart]);

  const openNav = () => {
    setNavHeight("100%");
  };

  const closeNav = () => {
    setNavHeight("0%");
  };

  return (
    <header style={{ color: "#ffffff"}}>
              <div className='leftLinks'>
              
              <div className='menulinks1'><Link className='links' to="/"><img className='logoimgs' src={`${process.env.PUBLIC_URL}/727.png`} /></Link></div>
                
                <div className='menulinks topmargin'><Link className='links' to="/events">Events</Link></div>
                <div className='menulinks topmargin'><Link className='links' to="/aboutUs">About Us</Link></div>
                <div className='menulinks topmargin'><Link className='links' to="/GearUp">Gear </Link> <Link className='links' to="/cart"> <FontAwesomeIcon icon={faShoppingCart} style={{ fontSize: "24px" }} />
                {cart.length > 0 && (
      <span
        style={{
          position: "relative",
          top: "-12px",
          right: "-10px",
          background: "#b08e4b",
          color: "white",
          borderRadius: "50%",
          padding: "2px 10px",
          fontSize: "12px",
        }}
      >
        {cart.length}
      </span>
    )}
                 </Link></div>
                <div className='Contact topmargin'><Link className='links' to="/ContactUs">Contact Us</Link></div>
                <div className='Donate topmargin' style={{marginTop: "115px;"}}>
                    <Link className='linkingDonate' to="/donate">Donate</Link>
                    <Link className='linkingfundraider' to="/fundraiser">Join Our <br></br> Fundraiser</Link>
                
                </div>
                
                <span className='hamburger' style={{fontSize:"38px",pointerEvents:"pointet"}} onClick={openNav}>&#9776;</span>

               

              </div>

              
            
              <div className='leftLinks'>
              <div className='Social'></div>
              </div>

              <div id="myNav" className="overlay" style={{ height: navHeight }}>
              <a href="javascript:void(0)" className="closebtn" onClick={closeNav}>&times;</a>
              <div class="overlay-content">
                <a><Link className='links' to="/events">Events</Link></a>
                <a><Link className='links' to="/aboutUs">About Us</Link></a>
                <a><Link className='links' to="/GearUp">Gear</Link></a>
                <a><Link className='links' to="/ContactUs">Contact Us</Link></a>
                <a style={{justifyContent: "center", display:"grid"}}><Link style={{width:"150px"}} className='linkingDonate' to="/donate">Donate</Link></a>
                <a><Link className='linkingfundraider' to="/fundraiser">Join Our Fundraiser</Link></a>
              </div>
            </div>



            </header>
  )
}