import React from 'react';
import { Navbar } from "../layout/navbar";
import './../style/fundraiser.css'
import { faBorderNone } from '@fortawesome/free-solid-svg-icons';

export const Fundraiser = () => {
  return (
    <div>
        <Navbar />
        <div style={{fontSize: "60px", color: "#ffffff"}}> </div>
        <div>&nbsp;</div>
        <div style={{color: "#000", width: "95%", textAlign: "center", fontSize:"20px", margin:"0 auto", backgroundColor:'#ffffff'}}>


                       <div className='funbackheader'>
                        <p>&nbsp;</p>

                     <p  style={{ fontSize:'34px'}}>  Help support and encourage the youth within our </p>
                     <p style={{ fontSize:'34px'}}> communities through the gift of music </p>

                         <p style={{ textDecoration:'underLine',fontSize:'34px'}}> Sponsorship Opportunities</p>


                         <p  style={{fontSize:'18px', lineHeight:'14px'}}> July 11th, 2025 at the Summer Kick-off <br/>
                         of Party in the Square, in Clinton Square - 5:00pm</p>

                         <p>&nbsp;</p>

                         <p style={{fontFamily:'arial italic',fontSize:'31px',textAlign:'left', marginLeft:'30px'}}><div  className='left'>The Golden Saxophone Level:$5,000 </div> <div className='right'> <button className='buttonstyle' ><a href='/fundraisercheckout/1'>Purchase</a></button> </div> </p>



                       </div>


                       <div className='firsttext'>

                        <p>-(2) Personalized 3ft x 6ft banners w/your company’s name and logo. (1) displayed at <br></br>
                        &nbsp;&nbsp;the entry to the event tent, and (1) placed at the stage area for all 6 weeks of Party <br></br>
                        &nbsp; In The Square Event Dates.</p>

    <p>-Company’s name and Logo on all printed and digital information for both the fundraiser <br></br>
    &nbsp;and Party in the Square</p>


    <p>-Recognition on all social media platforms for both the fundraiser and Party in the Square<br></br>
    &nbsp;-727 Instrumental merchandise</p>

    <p>&nbsp;-(1)6-person table<br></br>&nbsp;-Includes access to aprivate bar, open bar, food truck vouchers </p>



                       </div>
       

                          <div   className='mainfundarise'>

                            <div className='bottomfundarise'>


                            <p>&nbsp;</p>

                          <p style={{fontFamily:'arial italic',fontSize:'31px',textAlign:'left'}}><div  className='left'>The Silver Flute Level: $3,500  </div> <div className='right'> <button className='buttonstyle' ><a href='/fundraisercheckout/2'>Purchase</a></button> </div>  </p>

                          <p>-(1)Personalized 3ft x 6ft banner w/your company’s name and logo displayed at <br></br>
                          &nbsp;the entry to the event tent</p>

                          <p>-Company’s name and Logo on all printed and digital information for both the fundraiser<br></br>
                          &nbsp;and Party in the Square</p>


                          <p>-Recognition on all of the Foundation’s social media platforms <br></br>
                          -727 Instrumental merchandise </p>


                          <p>&nbsp;-(1)6-person table<br></br>&nbsp;-Includes access to aprivate bar, open bar, food truck vouchers </p>


                          </div>




                          <div className='bottomfundarise2'>


                            <p>&nbsp;</p>

                          <p style={{fontFamily:'arial italic',fontSize:'31px',textAlign:'left'}}><div  className='left'>The Bronze Trumpet Level: $2,000  </div> <div className='right'>  <button className='buttonstyle' ><a href='/fundraisercheckout/3'>Purchase</a></button> </div> </p>

                          <p>Company’s name and Logo on all printed and digital information<br></br>
                          -Recognition on all of the Foundation’s social media platforms </p>

                          <p>-727 Instrumental merchandise </p>


                          <p>&nbsp;-(1)6-person table<br></br>&nbsp;-Includes access to aprivate bar, open bar, food truck vouchers </p>


                          <p>&nbsp;</p>
                          <p>&nbsp;</p>
                          <p>&nbsp;</p>



                          </div>




                          <div className='bottomfundarise3'>


                            <p>&nbsp;</p>

                          <p style={{fontFamily:'arial italic',fontSize:'31px',textAlign:'left'}}> <div  className='left'> Individual Tickets: $100 </div> <div className='right'> <button className='buttonstyle' ><a href='/fundraisercheckout/4'>Purchase</a></button>  </div> </p>

                          <p>Includes access to a private bar, open bar, (1) food truck voucher</p>

                          <p>&nbsp;</p>

                          <p>&nbsp;</p>

                          <p>&nbsp;</p>
                          <p>&nbsp;</p>


                          </div>







                          </div>










        </div>





            
            
           

            


           

            <div>


              

             

            
            </div>

            

            <div>&nbsp;</div>

    </div>
  )
}