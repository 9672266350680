import React from 'react';
import { Navbar } from "../layout/navbar";
import './../style/aboutUs.css'

export const Success = () => {
  return (
    <div>
        <Navbar />
        <div style={{fontSize: "60px", color: "#ffffff"}}> Payment Complete </div>
        <div>&nbsp;</div>
        <div style={{color: "#ffffff", width: "75%", textAlign: "center", fontSize:"20px", margin:"0 auto"}}>
         Thank you for  your  Purchase! <br></br>
         Your Payment has  completed successfully <br></br>
         (Please allow for up to 2 weeks for delivery)
         
        </div>
<div>&nbsp;</div>
           

            <div>&nbsp;</div>

            
           

            


            <div>&nbsp;</div>
            <div style={{height: "5px", background: "#fff", margin: "0 auto", width: "90%"}}>&nbsp;</div>
            <div>&nbsp;</div>

            

            

            

    </div>
  )
}