import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './../style/home.css'
import { Navbar } from '../layout/navbar'
import axios from 'axios';
export const Homepage = () => {

  const [items, setItems] = useState([]);
  useEffect(() => {
      axios.get('https://727instrumental.org/backend/public/api/merchandise')
      .then(response => {
          setItems(response.data);
      })
      .catch(error => {console.error(("API CRASHED !", error));
      });
  },[]);
  const navigate = useNavigate();

  const goToProduct = (id) => {
    navigate(`/product/${id}`); // Navigate to the product details page
  };

  return (
    <div className='container'>
        <div className='mainContent'>
        <Navbar /> 
            <div className='leftText'>
              <h1 className='mainText'>To inspire and grow<br/>the love of music, <br/>by providing musical instruments<br/>and lessons to the underserved<br/>youth in our area.</h1>
            </div>
            <div className='rightText'>
              <img src='./sax.jpg' className='heroimages' />
            </div>

            <div>

            <img src='./727-logo-main.png' style={{position: 'fixed',right: '28px',bottom: '120px'}} />

            </div>
            

            <div className='productrows' style={{clear: 'both',background: 'white'}}>

<div className='mainShopContainer'>


{items.map((item, index) => {

const productImages = item.image_url ? item.image_url.split(",") : ['default.jpg'];

return (
            <div className='Blocks4'>
                        
                        <div className='productimgblock'>
                                        <img src={item.image_url 
                                            ? `https://727instrumental.org/backend/public/products/${productImages[0]}` 
                                            : 'https://727instrumental.org/backend/public/products/default.jpg'} 
                                        alt={item.name || "Default Product"} 
                                        className="itemImage" 
                                        />
                        </div>
                        <div className='itemname'><span>{item.name} </span></div>
                        <div className='footerstrip'>
<div className='itemPrice'>
<p>${item.price}</p>
</div>
<div className='shopBtn'>
<input type='submit' value="+"  onClick={() => goToProduct(item.id)}/>
</div>
</div>
            
        </div>
);
})}



    
    </div>
</div>

</div>

    </div>
  )
}
