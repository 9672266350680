import React, { useEffect, useState } from 'react';
import { Navbar } from '../layout/navbar';
import './../style/event.css'
import axios from 'axios';

export const Events = () => {
    const [items, setItems] = useState([]);
    useEffect(() => {
        axios.get('https://727instrumental.org/backend/public/api/events')
        .then(response => {
            setItems(response.data);
            console.log(response.data);
        })
        .catch(error => {console.error(("API CRASHED !", error));
        });
    },[]);
  return (
    <div>
        <Navbar />

        <h1 style={{color: "#fff"}}></h1>

        

        <div className='mainShopContainer'>


        {items.map((item, index) => {

const productImages = item.image_url ? item.image_url.split(",") : ['default.jpg'];

return (
                 
        
    <div className='Blocks5' style={{ position: 'relative'}}>
                <div  className='dateevent'> 
                    <span>{item.eventMonth}</span>
                    <span>{item.eventdate}</span>

                </div>
                    <div>
                      { /* <img src='./event1.jpg' alt='' className='itemImage' /> */}
                        
                        <img src={item.image_url 
                                                    ? `https://727instrumental.org/backend/public/eventimg/${productImages[0]}` 
                                                    : 'https://727instrumental.org/backend/public/eventimg/default.jpg'} 
                                                alt={item.name || "Default Event"} 
                                                className='itemImage'
                                                />


                    </div>
                    <div className='itemDes'>
                        <p style={{ color:"#5e5c61", font:"18px"}} className='eventname'>{item.name}</p>

                        {Array.isArray(item.eventdetail) ? (
                                    item.eventdetail.map((itemp, index) => (
                                        <div key={index} style={{ textAlign:'left',marginLeft:'20px',color: '#0f0e0e91' }}>
                                            <p>{itemp.name}</p>
                                            <p>{itemp.time}</p>
                                        </div>
                                    ))
                                ) : (
                                    <p>Loading...</p>
                                )}



                               
                    </div>
                        <div className='buttonwrap' style={{ marginTop:'20px'}}>

                                    {item.type === "paid" ? (
                                        <a href={`/buy-tickets/${item.id}`} className='buyticket'> Buy Tickets </a>
                                        ) : (

                                            <div >

                                                <div style={{display:'flex', alignItems:'center'}}> <span style={{marginTop: '35px'}}>Free Entry  </span>  <a href={`${item.facebook_url}`}> <img className='logoimgs' src={`${process.env.PUBLIC_URL}/ficon.png`} style={{ width:'50px', height:'50px', verticalAlign:'middle'}} /> </a> </div>


                                            </div>
                                        
                                        )}
                        
                        
                        
                        


                        </div>
                        <div className='shopBtn'>
                        {/* <input type='submit' value="Buy Now" /> */}
                        </div>
</div>

                 
                 
                 
                 
                 
                
);
})}



</div>
            
</div>
  
  )
}