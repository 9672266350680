import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Navbar } from "../layout/navbar";
import './../style/productDetails.css'
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

export const EditProductDetails = () => {
  const [product, setProduct] = useState({ qty: 1 });
  const [productvariations, setProductvariations] = useState([]);
  const [selectedSize, setSelectedSize] = useState(null); // Default filter
  const [selectedcolor, setselectedcolor] = useState(null); 

  const [colorset, setcolorset] = useState(null); // Default filter

  const [selectvariations, setselectvariations] = useState([]);

  const [images, setImages] = useState([]); // Dynamic images array
  const [cartindex, setcartindex] = useState(null);

  const [currentImage, setCurrentImage] = useState(""); // Current selected image
  const baseUrl = "https://727instrumental.org/backend/public/products";
  
    const { id, indexupdate } = useParams();
     const navigate = useNavigate();

    const [cart, setCart] = useState(() => {
      const savedCart = localStorage.getItem('cart');
      return savedCart ? JSON.parse(savedCart) : [];
    });
  
  /*  const addToCart = (item) => {

      cart.filter(item => item.id == id)

      const updatedCart = [...cart, item];
      setCart(updatedCart);
      localStorage.setItem('cart', JSON.stringify(updatedCart));
      const savedCart = localStorage.getItem('cart');

      console.log(savedCart);
     navigate(`/cart`);
    };

    */

    const addToCart = (item) => {

      //console.log(item);

      if(selectedSize==null && uniqueSizes.length>0){
        alert('Please select the size');

      }else if(selectedcolor==null){

        alert('Please select the color');

      }else{
      
        

        const cartIndex = parseInt(cartindex, 10); // Convert cartindex to a number

        if (!isNaN(cartIndex) && cartIndex >= 0 && cartIndex < cart.length) {
          // Update the item at the specified index
          const updatedCart = cart.map((cartItem, index) =>
            cartIndex === index
              ? {
                  ...cartItem,
                  qty: item.qty,
                  size: selectedSize,
                  color: selectedcolor,
                  subtotal: (item.qty) * item.price,
                  cartimg: item.alis + "-" + selectedcolor + ".png",
                }
              : cartItem
          );
        
          // Update localStorage with the new cart
          localStorage.setItem('cart', JSON.stringify(updatedCart));
          console.log("Updated Cart:", updatedCart);
        } else {
          console.error("Invalid cart index:", cartIndex);
        }
      
      
     
     const savedCart = localStorage.getItem('cart');

     //console.log(savedCart);

      navigate(`/cart`);
      }


    };


    // Fetch product details based on the ID
  useEffect(() => {
    // Simulate an API call (replace with real API call)
    const fetchProductDetails = async () => {
    //  const productData = {
     //   1: { name: 'Product 1', price: '20.00', description: 'This is product 1',image_url:'product-1.png' },
     //   2: { name: 'Product 2', price: '30.00', description: 'This is product 2',image_url:'product-2.png' },
        // Add more products here as needed
     // };

      try {
        const response = await fetch('https://727instrumental.org/backend/public/api/productdetails', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ productid:id }), // Sending productid in the body
        });
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        
       // console.log(response);
        const productData = await response.json(); // Assuming the API returns a JSON object
        console.log('dhirendra');
        console.log(productData);
        setProductvariations(productData.variations);


       // setProducts(data); // Assuming `data` is an array of products
        //setLoading(false);
        const productDetails = productData.products[id]; // Fetch product based on ID
      setProduct({...productDetails,qty:1 });

      
          const productImages = productDetails.image_url ? productDetails.image_url.split(","): ['default.jpg'];
  //console.log('dhirendra');
  //console.log(productImages);

  const fullImageUrls = productImages.map((img) =>
    img ? `${baseUrl}/${img}` : `${baseUrl}/default.jpg`
  );

          setImages(fullImageUrls);
         // setCurrentImage(fullImageUrls[0]); // Set the first image as the default

    } catch (error) {
       // setError(error.message);
       // setLoading(false);
    }

      
    };

    fetchProductDetails();
    setcartindex(indexupdate);

    console.log('cart');
    console.log(cart[indexupdate]);

    setselectedcolor(cart[indexupdate].color);
   
    const curentimageval=`${baseUrl}/${cart[indexupdate].cartimg}`;


    setCurrentImage(curentimageval);
    setSelectedSize(cart[indexupdate].size);

    setProduct(prevProduct => ({
      ...prevProduct,
      qty:Number(cart[indexupdate].qty)
  }));



  }, [id,indexupdate]);

  const handleQtyChange = (value) => {
    // Ensure qty is a positive number and update the state
    setProduct(prevProduct => ({
        ...prevProduct,
        qty: value > 0 ? Number(value) : 0
    }));
};


const handlesetcolor = (value) => {

  const filteredItems = productvariations.filter((item) => item.size === value);

  console.log(value);

  setcolorset(filteredItems);
  setSelectedSize(value);

 

};

const handleselectcolor = (value) => {

  const filteredItems = productvariations.filter((item) => item.size === selectedSize && item.color === value);

  console.log(filteredItems);

  setselectvariations(filteredItems);
  setselectedcolor(value);

  const curentimageval=`${baseUrl}/${product.alis}-${value}.png`;

  setCurrentImage(curentimageval);



};

const handleImages = (img)=>{
  setCurrentImage(img);
  const fileName = img.split("/").pop();
  const nameWithoutExtension = fileName.split(".")[0]; // "Beanie-Cap-Black"



// Step 2: Split the remaining string by the delimiter (e.g., "-")
const parts = nameWithoutExtension.split("-"); // ["Beanie", "Cap", "Black"]

// Step 3: Get the last part, which is the color name
const colorName = parts[parts.length - 1];

setselectedcolor(colorName);


}

const uniqueSizes = productvariations.reduce((acc, variation) => {
  if (variation.size && variation.size.trim() !== '' && !acc.some(item => item.size === variation.size)) {
    acc.push(variation);
  }
  return acc;
}, []);

const uniquecolor = productvariations.reduce((acc, variation) => {
  if (variation.color && variation.color.trim() !== '' && !acc.some(item => item.color === variation.color)) {
    acc.push(variation);
  }
  return acc;
}, []);





 

  return (
    <>
    <div>
     <Navbar />

                <div className='wrapper'>

                  <h1 style={{ color:'white'}}>PRODUCT DESCRIPTION</h1>

                            <div className='shop'>
                                <div className='box'>
                                {product ? (
                                  <div>
                                  <div  className='pthumb'>
                                    <img src={currentImage} alt="Selected" style={{ width: "500px", height: "auto",maxHeight:'550px' }} className='thimg' />
                                  </div>

                                  <div style={{ marginRight: "20px", marginTop: "20px",background: 'white',display: 'flex', justifyContent: "center", height:'120px',alignItems: "center" }}>
                                    {images.length > 0 ? (
                                      images.map((image, index) => (
                                        <img
                                          key={index}
                                          src={image}
                                          alt={`Thumbnail ${index + 1}`}
                                          style={{
                                            width: "100px",
                                            height: "auto",
                                            borderRadius: "5px",
                                            marginRight: "10px",
                                            cursor: "pointer",
                                            border: currentImage === image ? "2px solid blue" : "none",
                                          }}
                                          onClick={()=>handleImages(image)}
                                        />
                                      ))
                                    ) : (
                                      <p>No images available</p>
                                    )}
                                  </div>

                                  </div>

                                  
                                ):(<p>Loading...</p>)}

                                {product ? (
                                  <div className='content' style={{marginLeft: '10%'}}>

                                    <div className='itemdetailrow'>

                                   


                                    </div>

                                    <div className='itemdetailrow'>

                                    <h3 style={{marginLeft: "30px",fontSize: '16px',
  fontWeight: 'inherit'}} >&nbsp;&nbsp;&nbsp;Item Name:&nbsp;{product.name} {selectedcolor ? (<>({selectedcolor})</>):""}</h3>


                                    </div>

                                    <div className='itemdetailrow'>

                                    <h4 style={{marginLeft: "30px",fontSize: '16px',
  fontWeight: 'inherit'}} className='itemname1'>&nbsp;&nbsp;&nbsp;Item Price:&nbsp;${product.price}</h4>


                                    </div>
                                    {uniqueSizes.length > 0 ? (
  <>
    <div className="itemdetailrow" style={{ textAlign: 'left' }}>
      <ul style={{ display: 'inline-flex' }}>
        <li style={{ marginLeft: "30px", display: "inline" }}>
          Available Size:&nbsp;
        </li>
        {uniqueSizes.map((variation, index) => (
          <li
            key={index}
            style={{ display: 'inline' }}
            onClick={() => handlesetcolor(variation.size)}
          >
            <span
              className="size"
              style={{
                backgroundColor: selectedSize === variation.size ? 'green' : 'transparent',
              }}
            >
              {variation.size}
            </span>
            <br />
            <span className="sizeprice">${variation.price}</span>
            <br />
            {/*<span className="sizeprice">{variation.stock}</span>
            <br />
            <span className="sizeprice2">In Stock</span> */}
          </li>
        ))}
      </ul>
    </div>

    <div className="itemdetailrow" style={{ textAlign: 'left', marginLeft: "30px" }}>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;color:&nbsp;
      <select onChange={(e) => handleselectcolor(e.target.value)} className="selectcls" value={selectedcolor}>
      {selectedcolor ? (<><option value={selectedcolor}>{selectedcolor}</option></>):(<><option value="">choose an option</option></>)}
        
        {uniquecolor && uniquecolor.map((colorvariation, index) => (
          <option key={index} value={colorvariation.color}>
            {colorvariation.color}
          </option>
        ))}
      </select>
    </div>
  </>
) : (
  <div className="itemdetailrow" style={{ textAlign: 'left', marginLeft: "30px" }}>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;color:&nbsp;
      <select onChange={(e) => handleselectcolor(e.target.value)} className="selectcls" value={selectedcolor}>
      {selectedcolor ? (<><option value={selectedcolor}>{selectedcolor}</option></>):(<><option value="">choose an option</option></>)}
        { productvariations.map((colorvariation, index) => (
          <option key={index} value={colorvariation.color}>
            {colorvariation.color}
          </option>
        ))}
      </select>
      
    </div>
)}







                                 

                                  <div  className='itemdetailrow' style={{textAlign:'left',marginLeft: "30px",marginTop:'10px'}}>
                                      <p className='unit'>Item Quantity: <input type="number" min={1} defaultValue={1} value={product.qty} onChange={(e) => handleQtyChange(e.target.value)} /></p>
                                    </div>

                                  
                                  <div>&nbsp;</div>
                                  <p className='unit2'><input type='submit' value="Add To Cart" style={{ cursor: "pointer"}} onClick={() => addToCart(product)}/></p>

                                  


                                  </div>
                              ) : (<p>Loading...</p>)}
                              </div>
                            </div>                    
                </div>
    </div>
    </>
  )
}