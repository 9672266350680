import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { Navbar } from "../layout/navbar";
import './../style/checkout.css'
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark, faPen, faArrowRight, faShoppingCart } from '@fortawesome/free-solid-svg-icons'; // Import the cancel icon

let debounceTimer;
const debounce = (callback, delay) => {
  clearTimeout(debounceTimer);
  debounceTimer = setTimeout(callback, delay);
};

export const Ticketcheckout = () => {
  const [product, setProduct] = useState(null);
  const [termsAccepted, setTermsAccepted] = useState(false);
 
  const [isPayPalButtonDisabled, setIsPayPalButtonDisabled] = useState(true);
  const [fname,setfname]= useState('');
  const [lname,setlname]= useState('');

  const [YourFullName,setYourFullName]= useState('');
  const [useremail, setUseremail] = useState("");
  const [Address,setAddress]= useState('');
  const [City,setCity]= useState('');
  const [State,setState]= useState('');
  const [Zip,setZip]= useState('');

  const [ShipFullName,setShipFullName]= useState('');
  const [ShipAddress,setShipAddress]= useState('');
  const [ShipCity,setShipCity]= useState('');
  const [ShipState,setShipState]= useState('');
  const [ShipZip,setShipZip]= useState('');
  const [shippingandaddres,setshippingandaddres]= useState(false);

 

  const [isTyping, setIsTyping] = useState(false);

  const YourFullNameRef = useRef(YourFullName);
  const useremailRef = useRef(useremail);

  const AddressRef = useRef(Address);
  const CityRef = useRef(City);
  const StateRef = useRef(State);
  const ZipRef = useRef(Zip);

  const ShipFullNameRef = useRef(ShipFullName);
  const ShipAddressRef = useRef(ShipAddress);
  const ShipCityRef = useRef(ShipCity);
  const ShipStateRef = useRef(ShipState);
  const ShipZipRef = useRef(ShipZip);

  useEffect(() => {

    
    YourFullNameRef.current = YourFullName;
    useremailRef.current = useremail; // Update ref whenever state changes
    AddressRef.current = Address; // Update ref whenever state changes
    CityRef.current = City; // Update ref whenever state changes
    StateRef.current = State; // Update ref whenever state changes
    ZipRef.current = Zip; // Update ref whenever state changes


    ShipFullNameRef.current = ShipFullName; // Update ref whenever state changes
    ShipAddressRef.current = ShipAddress; // Update ref whenever state changes
    ShipCityRef.current = ShipCity; // Update ref whenever state changes
    ShipStateRef.current = ShipState; // Update ref whenever state changes
    ShipZipRef.current = ShipZip; // Update ref whenever state changes
    
  }, [useremail, ShipFullName, ShipAddress, ShipCity, ShipState, ShipZip,YourFullName,Address,City,State,Zip]);


  const validateFields = () => {
    // Check if all required fields are filled
    return (
      
      YourFullName.trim() !== "" &&
      useremail.trim() !== "" &&
      Address.trim() !== "" &&
      City.trim() !== "" &&
      State.trim() !== "" &&
      Zip.trim() !== "" &&
      ShipAddress.trim() !== "" &&
      ShipCity.trim() !== "" &&
      ShipState.trim() !== "" &&
      ShipZip.trim() !== ""
    );
  };

  useEffect(() => {
    // Update PayPal button state based on field validation
    setIsPayPalButtonDisabled(!validateFields());
  }, [useremail, ShipAddress, ShipCity, ShipState, ShipZip,YourFullName,Address,City,State,Zip]);


  const handleInputChange = (setter) => (e) => setter(e.target.value);

  

  
  

  
  
  

    const { id } = useParams();

    const navigate = useNavigate();

    const [ticketcart, setTicketcart] = useState(() => {
      const savedCart = localStorage.getItem('ticketcart');
      return savedCart ? JSON.parse(savedCart) : [];
    });
  
    const addToCart = (item) => {
      const updatedCart = [...ticketcart, item];
      setTicketcart(updatedCart);
      localStorage.setItem('ticketcart', JSON.stringify(updatedCart));
      const savedCart = localStorage.getItem('ticketcart');

     // console.log(savedCart);
     navigate(`/checkout`);
    };
    // Fetch product details based on the ID
  useEffect(() => {
    // Simulate an API call (replace with real API call)
    const fetchProductDetails = async () => {
      const productData = {
        1: { name: 'Product 1', price: '20.00', description: 'This is product 1',image_url:'product-1.png' },
        2: { name: 'Product 2', price: '30.00', description: 'This is product 2',image_url:'product-2.png' },
        // Add more products here as needed
      };

      const productDetails = productData[id]; // Fetch product based on ID
      setProduct(productDetails);
    };

    fetchProductDetails();
  }, [id]);


  const handleCreateOrder = async (data, actions) => {
    // Validate the cart before proceeding

    const currentYourFullName = YourFullNameRef.current;
    const currentEmail = useremailRef.current;
    const currentAddress =  AddressRef.current;
    const currentCity = CityRef.current;
    const currentState = StateRef.current;
    const currentZip = ZipRef.current;

    const currentShipAddress = ShipAddressRef.current;

    const currentShipCity = ShipCityRef.current;

    const currentShipState = ShipStateRef.current;

    const currentShipZip = ShipZipRef.current;
   

    if (ticketcart.length === 0) {
      alert("Sorry, your ticket cart is empty.");
     
      throw new Error("Ticket Cart is empty"); // Stops the PayPal flow
    }

  // console.log(useremail);
   //alert(useremail+'dhirendra');
  
    try {
      // Send the create order request to the backend
      const response = await fetch("https://727instrumental.org/backend/public/api/paypal/create-order-ticket", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          items: ticketcart,
          useremail:currentEmail,
          name:currentYourFullName,
          address:currentAddress,
          city:currentCity,
          state:currentState,
          zip:currentZip,
          ShipAddress:currentShipAddress,
          ShipCity:currentShipCity,
          ShipState:currentShipState,
          ShipZip:currentShipZip,
          totalprice: calculateTotal().toFixed(2),
        }),
      });
  
      if (!response.ok) {
        throw new Error("Failed to create order");
      }
  
      const orderData = await response.json();
      return orderData.id; // Return the PayPal order ID
    } catch (error) {
      console.error("Error creating order:", error);
      alert("Unable to create order. Please try again later.");
      throw error; // Stops the PayPal flow
    }
  };


  const handleOnApprove = async (data, actions) => {
    try {
      // Capture the payment on the backend
      const response = await fetch("https://727instrumental.org/backend/public/api/paypal/capture-order-ticket", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ orderID: data.orderID }) // Ensure orderID is being sent correctly
      });
  
      // Check if response is successful
      if (!response.ok) {
        throw new Error("Failed to capture the order.");
      }
  
      const captureData = await response.json();
      console.log("Capture Data:", captureData);
  
      if (captureData.success) {
        setTicketcart([]); // Empty the cart in state
         localStorage.removeItem('ticketcart'); // 
        window.location.href = "/payment-success";  // Redirect to your success page, or use window.close() if in pop-up mode
      } else {
        alert("Payment verification failed.");
      }
  
      // Redirect or close the window (depending on how the PayPal window was opened)
      
    } catch (error) {
      console.error("Error in payment approval:", error);
      alert("Something went wrong. Please try again.");
    }
  };


  useEffect(() => {
    localStorage.setItem('ticketcart', JSON.stringify(ticketcart));
  }, [ticketcart]);

  // Function to remove an item from the cart
  const removeItemFromCart = (removitem) => {
    const updatedCart = ticketcart.filter(
      (item) => !(item.id === removitem.id && item.size === removitem.size && item.color === removitem.color)
    );
    setTicketcart(updatedCart);
  };

  const editItemFromCart = (id) => {
    navigate(`/product/${id}`); 
  };
  

  const calculateTotal = () => {
    return ticketcart.reduce((total, item) => {
      return total + item.price * item.qty; // Multiply price by quantity for each item
    }, 0);
  };

  


  const totalWithFee = parseFloat((calculateTotal() + 6.99).toFixed(2)) ;



  const handleOnClick = (data, actions) => {

    const currentYourFullName = YourFullNameRef.current;
    const currentEmail = useremailRef.current;
    const currentAddress =  AddressRef.current;
    const currentCity = CityRef.current;
    const currentState = StateRef.current;
    const currentZip = ZipRef.current;

    const currentShipAddress = ShipAddressRef.current;

    const currentShipCity = ShipCityRef.current;

    const currentShipState = ShipStateRef.current;

    const currentShipZip = ShipZipRef.current;

    if (ticketcart.length === 0) {
      alert("Sorry, your cart is empty.");
      return actions.reject(); // Prevent PayPal popup
    }else if (currentYourFullName.trim() === "") {
                        alert("Please provide your Name");
                        return actions.reject(); // Prevent PayPal popup
    }else if(!currentEmail || currentEmail.trim() === ""){
                    alert("Please provide a valid email address.");
                    return actions.reject(); // Prevent PayPal popup

    }else if(currentAddress.trim() === ""){

      alert("Please provide a valid   Address.");
      return actions.reject(); // Prevent PayPal popup

    }else if(currentCity.trim() === ""){
                  alert("Please provide a valid City.");
                  return actions.reject(); // Prevent PayPal popup
    }else if(currentState.trim() === ""){
      alert("Please provide a valid State.");
      return actions.reject(); // Prevent PayPal popup
    }else if(currentZip.trim() === ""){
      alert("Please provide a valid Zip");
      return actions.reject(); // Prevent PayPal popup
    }else if(currentShipAddress.trim() === "" ){
                    alert("Please provide a valid Shipping  Address.");
                    return actions.reject(); // Prevent PayPal popup

    }else if(currentShipCity.trim() === ""){

      alert("Please provide a valid City Name");
      return actions.reject(); // Prevent PayPal popup

    }else if(currentShipState.trim() === ""){

      alert("Please provide a valid State Name");
      return actions.reject(); // Prevent PayPal popup

    }else if(currentShipZip.trim() === ""){

      alert("Please provide a valid Zip Name");
      return actions.reject(); // Prevent PayPal popup

    }
    
   
    //console.log("User email:", currentEmail);

    

    
   
    return actions.resolve();
   
  };





  

  const handleshippingdetails = () => {

    
    if(!shippingandaddres){
     

      setshippingandaddres(true);

      setShipAddress(Address);
      setShipCity(City);
      setShipState(State);
      setShipZip(Zip);


    }else{
      setshippingandaddres(false);
      setShipAddress('');
      setShipCity('');
      setShipState('');
      setShipZip('');

    }

  }

  
  
   

 
  
  return (
    <div>
     <Navbar />

                <div className='checkmain' >

                         <div className='checkoutstrip'>CHECKOUT</div> 

                   <div className='checkoutinner'>      


                         <div className='checkoutleft'>

                           <div className='shippingdetails'> BILLING AND SHIPPING DETAILS</div>


                           <div className='fandlname'> 
                           <input type='text'   name='fname' className='inputbox' value={YourFullName}  onChange={handleInputChange(setYourFullName)} placeholder="Enter your Full Name " autoComplete="off"  />
                             </div>
                           <div className='fandlname'> <input type='text' className='inputbox' name='email' value={useremail}  onChange={handleInputChange(setUseremail)} placeholder="Enter your email" autoComplete="off"  />   </div>
                            
                           <div className='fandlname'> <input type='text' className='inputbox' name='address' value={Address}  onChange={handleInputChange(setAddress)}    placeholder="Enter your Address" autoComplete="off" />  </div>

                           <div className='fandlname'> <input type='text' className='inputbox' name='city' value={City}  onChange={handleInputChange(setCity)}  placeholder="Enter your City" autoComplete="off"  />  </div>

                           <div className='fandlname'> <input type='text' className='inputbox' name='state' value={State}  onChange={handleInputChange(setState)} placeholder="Enter your State" autoComplete="off"  />  </div>
                         
                           <div className='fandlname'> <input type='text' className='inputbox' name='zip' value={Zip}  onChange={handleInputChange(setZip)}  placeholder="Enter your  Postal / Zip Code" autoComplete="off"  />  </div>

                           <div className='shippingdetails'>Shipping Address Same As Billing Address <input type='checkbox'   onChange={handleshippingdetails}/></div>

                           
                           <div className='fandlname'> 
                             </div>
                            
                           <div className='fandlname'> <input type='text' className='inputbox' name='ShipAddress' value={ShipAddress}  onChange={handleInputChange(setShipAddress)} placeholder="Enter your Address" autoComplete="off"  />  </div>

                           <div className='fandlname'> <input type='text' className='inputbox' name='ShipCity' value={ShipCity} onChange={handleInputChange(setShipCity)} placeholder="Enter your City" autoComplete="off"  />  </div>

                           <div className='fandlname'> <input type='text' className='inputbox' name='ShipState' value={ShipState}  onChange={handleInputChange(setShipState)} placeholder="Enter your State" autoComplete="off"  />  </div>
                         
                           <div className='fandlname'> <input type='text' className='inputbox' name='ShipZip' value={ShipZip}  onChange={handleInputChange(setShipZip)} placeholder="Enter your  Postal / Zip Code" autoComplete="off"  />  </div>

                          

                           

                          
                         </div>

                         

                         

                         <div className='checkoutright'>

                                              <div className='checkoutdetail'>
                                                                              <div className='checkoutitem'>Image</div>

                                                                              <div className='checkoutitem'>Name</div>
                                                                              <div className='checkoutitem'> Qty</div>
                                                                              <div className='checkoutitem'>Price</div>
                                                                              
                                                                              <div className='checkoutitem'> Subtotal</div>
                                                
                                                </div> 

                                                {ticketcart.map((item, index) => {
                const productImages = item.image_url ? item.image_url.split(",") : ['default.jpg'];

                // Explicitly return the JSX
                return (
                    <div key={index}>
                        <div className='checkoutdetailcart' style={{opacity:"0.8s", color: "#ffffff"}}>
                            <div className='checkitem3'>
                                <img
                                    src={item.image_url
                                        ? `https://727instrumental.org/backend/public/eventimg/${productImages[0]}`
                                        : 'https://727instrumental.org/backend/public/products/default.jpg'}
                                    alt={item.name || "Default Product"}
                                    className="itemImage3"
                                />
                            </div>
                            <div className='checkitem3' style={{ fontSize:'16px'}}>{item.name}</div>
                            <div className='checkitem3'  style={{ fontSize:'16px'}}>{item.qty}</div>
                            <div className='checkitem2'  style={{ fontSize:'16px'}}>{item.price}</div>
                            <div className='checkitem2'  style={{ fontSize:'16px'}}>{item.subtotal}</div>
                        </div>
                        <div className='actions'>
                                <button className='cancle1' onClick={() => removeItemFromCart(item)} >
                                    <FontAwesomeIcon icon={faXmark} /> REMOVE
                                </button>
                                <button className='edit1' onClick={() => editItemFromCart(item.id)}>
                                    <FontAwesomeIcon icon={faPen} /> EDIT
                                </button>
                        </div>
                        <hr />

                        
                    </div>
                    
                );
            })}


<div className='shipMain'>

<div style={{width:'100%',display: 'inline-block',marginTop: '40px'}}> 
     <div style={{ width:'60%', textAlign:'left', float:'right',display:'block'}}>Cart totals</div>
</div>

<div className='shipmain2'> 
   <div style={{width:'100%',borderBottom: '2px solid white',display: 'block ruby',lineHeight: '50px' }}>
            <div style={{ width:'40%', textAlign:'left', float:'left',display:'block'}}>Subtotal</div>
            <div style={{ width:'60%', textAlign:'left', float:'left',display:'block'}}>${calculateTotal().toFixed(2)}</div>
     </div>

     <div style={{width:'100%',borderBottom: '2px solid white',display: 'flex' , marginTop:'10px', marginBottom:'10px',height: '70px'}}>
            <div style={{ width:'40%', textAlign:'left', float:'left',display:'block'}}>Shipping</div>
            <div style={{ width:'60%', textAlign:'left', float:'left',display:'block'}}>Flat rate:$6.99 <br/>for domestic shipping</div>
     </div>
     <div style={{width:'100%',borderBottom: '2px solid white',display: 'block ruby',lineHeight: '50px' }}>
            <div style={{ width:'40%', textAlign:'left', float:'left',display:'block'}}>Tax</div>
            <div style={{ width:'60%', textAlign:'left', float:'left',display:'block'}}>$0.00</div>
     </div>

     <div style={{width:'100%',lineHeight: '50px' }}>
            <div style={{ width:'40%', textAlign:'left', float:'left',display:'block'}}>Total</div>
            <div style={{ width:'60%', textAlign:'left', float:'left',display:'block'}}>${totalWithFee}</div>
     </div>

    
</div>


</div>


                                        <div className='checkoutdetail'>

                                          

                                        <PayPalScriptProvider options={{ "client-id": "AVNx-Z-S7SxRFQebH6kIVPMRO_itIRNgI-gDoeBKr6lumzCfAPTmUnGLOHoiv63IO6bXf6iLrk9UHBYq", currency: "USD" }}>
                                              <div style={{margin: "0 auto"}}>
                                                
                                                <PayPalButtons
                                                  createOrder={handleCreateOrder}
                                                  onApprove={handleOnApprove}
                                                  onError={(err) => console.error("PayPal error:", err)}
                                                 
                                                  onClick={handleOnClick}
                                                  disabled={isPayPalButtonDisabled}
                                                  style={{
                                                    label: "pay", // Changes the button label
                                                  }}
                                                 
                                                />
                                              </div>
                                            </PayPalScriptProvider>


                                           

                                        
                                                                              
                                                
                                                </div> 



                         </div>

                         

                </div>    


                                              
                  </div>
    </div>
  )
}